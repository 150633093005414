import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from './http.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { FennecApiClass, TokenClass } from 'src/app/object/fennecApiClass';
import { UpdateSecurityService } from '../security/update.security.service';
import { ConfigFileService } from '../config-file.service';
// tslint:disable: object-literal-key-quotes
// tslint:disable: variable-name
// tslint:disable: max-line-length
// tslint:disable: no-string-literal
// tslint:disable: triple-equals
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  static instance: ApiService;
  constructor(
    private http: HttpService,
    private _updateSecurityService: UpdateSecurityService,
    private _ConfigFileService: ConfigFileService
  ) {
    ApiService.instance = this;
   }
  public LogIn(login, password,captcha): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',      // tslint:disable-line: object-literal-key-quotes
        'Expires': '0'             // tslint:disable-line: object-literal-key-quotes
      })
    };
    let sub;

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject('Request Timeout');
      }, 3000);
      var toSend = {};
      toSend['login'] = login;       // tslint:disable-line: no-string-literal
      toSend['password'] = password; // tslint:disable-line: no-string-literal
      toSend['captcha'] = captcha; // tslint:disable-line: no-string-literal
      sub = this.http.Post(httpOptions, toSend, '/login').pipe(
        catchError(err => {
          reject(err);
          return throwError(err);
        })
      ).subscribe((value: FennecApiClass) => {
        if (value.data.access === true) {
          const token = new TokenClass(value.data.tokenLifeTime, value.data.endTokenLifeDate,
            value.data.token, value.data.access, value.data.message);
          resolve([token, value]);
        } else {
          reject(value.data.message);
        }
        sub.unsubscribe();
      });
    });
  }
  LogOut(token): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        token,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',      // tslint:disable-line: no-string-literal
        'Expires': '0'             // tslint:disable-line: no-string-literal
      })
    };
    return new Promise((resolve, reject) => {
      this.http.Get(httpOptions, '/logout').pipe(
        catchError(err => {
          reject(err);
          return throwError(err);
        })
      ).subscribe((value: FennecApiClass) => {
        this._updateSecurityService.securityTokenObject.next(value); //wysłanie obiektu do sprawdzenia tokenu w  celu przydłużenia czasu życia tokenu
        if (String(value.data) === 'true') {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });

  }

  public UploadFile(token_: string, uploadData, functionMode: string): Promise<any> {
    let sub;
    const httpOptions = {
      headers: new HttpHeaders({
        'token': token_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'  // tslint:disable: object-literal-key-quotes
      })
    };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();

        reject('Request Timeout');
      }, 240000);
      sub = this.http.UploadFile(httpOptions, uploadData, functionMode).pipe(
        catchError(err => {
          //this._updateSecurityService.securityLogOut.next(true);
          reject(err);
          return throwError(err);
        })
      ).subscribe(value => {
        this._updateSecurityService.securityTokenObject.next(value); //wysłanie obiektu do sprawdzenia tokenu w  celu przydłużenia czasu życia tokenu
        sub.unsubscribe();
        resolve(value);
      });
    });
  }
  public deleteFile(token_: string,fileID,functionMode): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        token: this.token.value.value != null ? this.token.value.value : '',
        'token':token_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    var sub;
    const JSON = {};
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      sub = this.http.Get(httpOptions, functionMode + fileID).pipe(
        catchError(err => {
          this._updateSecurityService.securityLogOut.next(true);
          reject(err);
          return throwError(err);
        })
      ).subscribe(value => {
        this._updateSecurityService.securityTokenObject.next(value); //wysłanie obiektu do sprawdzenia tokenu w  celu przydłużenia czasu życia tokenu
        if (value.generalCode === 200) {
          resolve(value.data.statusUnLink);
        } else {
          reject(value.generalCode);
        }
        sub.unsubscribe();
      });
    });
  }



  public change_privileges(token_: string, privilegesName: string, table: string, element_id: number,
    read: boolean = null, write: boolean = null, modification: boolean = null): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        token: token_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'  // tslint:disable: object-literal-key-quotes
      })
    };
    let sub;
    const JSON = {};
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      const param = '/changeprivileges/' + privilegesName + '/' + table + '/' +
        element_id + '/' + read + '/' + write + '/' + modification;
      sub = this.http.Get(httpOptions, param).pipe(
        catchError(err => {
          this._updateSecurityService.securityLogOut.next(true);
          reject(err);
          return throwError(err);
        })
      ).subscribe((value: FennecApiClass) => {
        this._updateSecurityService.securityTokenObject.next(value); //wysłanie obiektu do sprawdzenia tokenu w  celu przydłużenia czasu życia tokenu
        console.log(value)
        if (String(value.data.status) == 'true') {
          resolve(value.data);
        } else {
          reject(false);
        }
        sub.unsubscribe();
      });
    });
  }



  public GetFromDataBase(token_: string = null, table: string, wherename: Array<string>, where: Array<string>, fields: Array<string>,
    one: boolean = false, oneArray: boolean = false): Promise<any> {
    // tslint:disable-next-line: no-unused-expression
    token_ == null ? token_ = '' : null;
    const httpOptions = {
      headers: new HttpHeaders({
        token: token_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'  // tslint:disable: object-literal-key-quotes
      })
    };
    let sub;
    //* ustaweniea zapytania
    let param = '/' + table;
    for (let i = 0; i < wherename.length; i++) {
      param = param + '/' + wherename[i] + '/' + where[i];
    }
    // jeżeli są pola
    if (fields.length > 0) {
      param = param + '/?fields=';
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < fields.length; i++) {
        i === 0 ? param = param + fields[i] : param = param + ',' + fields[i];
      }
    }
    // * /////////

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      sub = this.http.Get(httpOptions, param).pipe(
        catchError(err => {
          this._updateSecurityService.securityLogOut.next(true);
          reject(err);
          return throwError(err);
        })
      ).subscribe((value: FennecApiClass) => {

        this._updateSecurityService.securityTokenObject.next(value); //wysłanie obiektu do sprawdzenia tokenu w  celu przydłużenia czasu życia tokenu
        if (String(value.security.accessGranted) == 'true') {
          one === true ? resolve(value.data[0][fields[0]]) : one = one;
          oneArray === true ? resolve(value.data[0]) : oneArray = oneArray;
          resolve(value.data);
       } else {
          setTimeout(() => {
              throw (value); // tslint:disable-line: no-string-throw
          }, 5);
          reject(false);
        }
        sub.unsubscribe();
      });
    });
  }
  public getFromConfigPublic(name): Promise<any> {
    return new Promise((resolve, reject) => {
      this.GetFromDataBase('', 'config', ['name'], [name], ['value'], true)
        .then(value => {
          resolve(value);
        }).catch(value => {
          // opóźnienie dane w celu możliwości zwrócenia wartośći null a następnie wyświetlenie błędu na konsoli. Powód: Błąd w konsoli działa jak return
          setTimeout(() => {
            throw ('Get from config (public) error: ' + name); // tslint:disable-line: no-string-throw
          }, 100);
          resolve(null);
        });
    });
  }

  GetImage(imageID, token_): Promise<any> {
    // tslint:disable-next-line: no-unused-expression
    token_ == null ? token_ = '' : null;
    const httpOptions = {
      headers: new HttpHeaders({
        'token': token_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    let sub;
    const JSON = {};
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      sub = this.http.Get(httpOptions, '/getImage/' + imageID).pipe(
        catchError(err => {
          reject(err);
          return throwError(err);
        })
      ).subscribe(value => {
        resolve(value);
        sub.unsubscribe();
      });
    });
  }
  public updateInDatabase(token_: string = null, JSON, table: string, wherename: Array<string>, where: Array<string>): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'token': token_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    let sub;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      let param = '/' + table;
      for (let i = 0; i < wherename.length; i++) {
        param = param + '/' + wherename[i] + '/' + where[i];
      }
      sub = this.http.Patch(httpOptions, param, JSON).subscribe((value) => {
        this._updateSecurityService.securityTokenObject.next(value); //wysłanie obiektu do sprawdzenia tokenu w  celu przydłużenia czasu życia tokenu
        resolve(value);
      });
    });
  }

  public deleteInDataBase(token_: string = null, table: string, wherename: Array<string>, where: Array<string>): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'token': token_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    let sub;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      let param = '/' + table;
      for (let i = 0; i < wherename.length; i++) {
        param = param + '/' + wherename[i] + '/' + where[i];
      }
      sub = this.http.Delete(httpOptions, param).subscribe((value) => {
        this._updateSecurityService.securityTokenObject.next(value); //wysłanie obiektu do sprawdzenia tokenu w  celu przydłużenia czasu życia tokenu
        resolve(value);
      });
    });
  }

  public GetLuckyNumber(): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    let sub;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      const param = '/luckyNumber';
      sub = this.http.Get(httpOptions, param).subscribe((value) => {

        resolve(value.data['value']);
      });
    });
  }



  public GetVisits(): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    let sub;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      const param = '/returnvisitscookies';
      sub = this.http.Get(httpOptions, param).subscribe((value) => {

        resolve(value.data);
      });
    });
  }
  public AddVisits(): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    let sub;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      const param = '/addtovisitscookies';
      sub = this.http.Get(httpOptions, param).subscribe((value) => {
        resolve(value.data);
      });
    });
  }

  public addToDatabase(token_: string = null, JSON: any, table: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'token': token_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    let sub;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      sub = this.http.Post(httpOptions, JSON, table).pipe(
        catchError(err => {
          reject(err);
          return throwError(err);
        })
      ).subscribe((value: FennecApiClass) => {
        this._updateSecurityService.securityTokenObject.next(value); //wysłanie obiektu do sprawdzenia tokenu w  celu przydłużenia czasu życia tokenu
        if (value.data != false) {
          resolve(value.data);
        } else {
          reject(false);
        }
        sub.unsubscribe();
      });
    });
  }



  public GetFromHome(webSiteCode_:string, table: string, wherename: Array<string>, where: Array<string>, fields: Array<string>,){
    const httpOptions = {
      headers: new HttpHeaders({
        webSiteID: this._ConfigFileService.ConfigObject.webSiteID,
        webSiteCode: webSiteCode_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'  // tslint:disable: object-literal-key-quotes
      })
    };
    let sub;
    //* ustaweniea zapytania
    let param = '/' + table;
    for (let i = 0; i < wherename.length; i++) {
      param = param + '/' + wherename[i] + '/' + where[i];
    }
    // jeżeli są pola
    if (fields.length > 0) {
      param = param + '/?fields=';
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < fields.length; i++) {
        i === 0 ? param = param + fields[i] : param = param + ',' + fields[i];
      }
    }
    // * /////////

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      sub = this.http.GetFromHome(httpOptions, param).pipe(
        catchError(err => {
          reject(err);
          return throwError(err);
        })
      ).subscribe(value => {
        //console.log(value)
        resolve(value);
    /*    if (String(value.security.accessGranted) == 'true') {
          resolve(value.data);
       } else {
          setTimeout(() => {
              throw (value); // tslint:disable-line: no-string-throw
          }, 5);
          reject(false);
        }*/
        sub.unsubscribe();
      });
    });
  }

  public short(token_:string, param:string,fromHome=false){
    const httpOptions = {
      headers: new HttpHeaders({
        'token': token_,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'  // tslint:disable: object-literal-key-quotes
      })
    };
    let sub;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 50000);

      if(!fromHome){
      sub = this.http.short(httpOptions, param).pipe(
        catchError(err => {
          reject(err);
          return throwError(err);
        })
      ).subscribe(value => {
        resolve(value);
        sub.unsubscribe();
      });
    }


      if(fromHome){
      sub = this.http.shortFromHome(httpOptions, param).pipe(
        catchError(err => {
          reject(err);
          return throwError(err);
        })
      ).subscribe(value => {
        resolve(value);
        sub.unsubscribe();
      });
    }


    });
  }


  pingHome(){
    let sub;
    const httpOptions = {
      headers: new HttpHeaders({
        webSiteID: this._ConfigFileService.ConfigObject.webSiteID,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'  // tslint:disable: object-literal-key-quotes
      })
    };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        sub.unsubscribe();
        reject(false);
      }, 5000);
      sub = this.http.GetFromHome(httpOptions, 'ping').pipe(
        catchError(err => {
          reject(err);
          return throwError("Brak połączenia z domem");
        })
      ).subscribe(value => {
          resolve(value);
        sub.unsubscribe();
      });
    });
  }







}
